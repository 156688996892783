html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
html {
  font-size: 10px;
}

body {
  // background: $lightPurple;
  background: #efefef;
}

#container {
  min-height: 100%;
  position: relative;
  & .content {
    padding-bottom: 260px;
    & .admin__area {
      margin-bottom: -30px;
    }
  }
}

.hero__image {
  background: url("/images/gjjames-hero2.png") no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 800px;
}

.admin__area {
  display: flex;

  @media all and (max-width: 900px) {
    flex-direction: column;
  }
}

.inner {
  max-width: 1350px;
  margin: 0 auto;
  @media all and (max-width: 768px) {
    &.messages {
      display: none;
    }
  }
  @media all and (max-width: 1400px) {
    padding: 2rem;
  }
}

.link__item {
  margin-top: 30px;
}

.home_Nav {
  background: $purple;

  @media all and (max-width: 1200px) {
    .nav__link--logo svg {
      width: 150px;
    }
  }

  @media all and (max-width: 414px) {
    .nav__link--logo svg {
      width: 50% !important;
    }
  }
}

.hidden__mobile {
  @media (max-width: 416px) {
    display: none !important;
  }
}

.hidden__tablet {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.hidden__desktop {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.products-content__flex {
  display: flex;
  .stores {
    width: 100%;
    height: 100%;
    margin: 0;
    .store {
      margin-top: 0;
    }
  }
}
