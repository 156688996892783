.tags {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.tag {
  font-size: 14px;
  display: block;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  &__choice {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: auto;
      right: -1.5rem;
      margin-right: -1rem;
      position: relative;
      z-index: 2;
      & + label {
        background: $grey;
        padding: 1rem 1rem 1rem 4rem;
      }
      &:checked + label {
        background: $purple;
        color: $white;
      }
    }
  }
  &__link {
    background: $lightPurple;
    color: $white;
    display: flex;
    border-radius: 4px;
    &--active {
      background: lighten($purple, 40%);
      .tag__text {
        color: white;
      }
    }
  }
  &__text {
    padding: 1rem 1rem;
    display: block;
    text-transform: capitalize;
    margin: auto;
  }
  &__count {
    padding: 1rem 1rem;
    background: white;
    border-left: 3px solid rgba(0, 0, 0, 0.1);
    margin-right: -1px;
    /* ugh */
    background-clip: padding-box;
  }
}

.tag__choice label {
  text-transform: capitalize;
}

.tags--flex {
  display: flex;
  justify-content: center;
  li {
    margin-right: 10px;
    // width: 100px;
  }
}

.categories--list .tags--flex {
  justify-content: unset;
}
