.hide {
  text-indent: -999999px;
  font-size: 0;
}

.button {
  border: 0;
  background: $button-purple;
  color: $white;
  font-family: sans-serif;
  font-weight: 600;
}

.btn-save {
  width: 50%;
  margin: 2rem auto;
}
.btn-edit {
  padding: 0.5em;
  letter-spacing: 2px;
  &:hover {
    background: white;
    color: purple;
  }
}

.btn-purple {
  padding: 1em;
  letter-spacing: 2px;
  background: $button-purple;
  color: $white;
  :hover {
    cursor: pointer;
  }
}

.avatar {
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  align-self: center;
}

.card {
  background: white;
  padding: 5rem;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

.category__card {
  min-width: 500px;
}

pre.error {
  padding: 5rem;
  background: white;
  line-height: 3;
  white-space: pre-line;
}
