.stores {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: -1.25em 0 0 -1.25em;
  @media all and (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.store {
  background: white;
  margin: 1.25em 0 1.5em 1.25em;
  width: calc(33.3333% - 14.25em);
  // margin-bottom: 1.5em;
  width: 18%;
  box-shadow: $shad;
  min-height: 246px;
  overflow: hidden;
  border: 2px solid $purple;
  &--wide {
    width: 100%;
  }
  &__details {
    padding: 1rem;
    p {
      line-height: 1.2;
      margin-bottom: 0;
    }
  }
  &:hover img {
    transform: scale(1.1);
  }
  @media all and (max-width: 1240px) {
    width: 18%;
  }
  @media all and (max-width: 1100px) {
    width: 23%;
  }
  @media all and (max-width: 960px) {
    width: calc(50% - 1.25em);
  }
  @media all and (max-width: 550px) {
    width: 100%;
    margin-left: 0;
  }
}

.store__actions {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 1px 0 rgba(255, 255, 255, 0.2);
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around;
}

.store__action {
  font-size: 10px;
  svg {
    width: 25px;
    fill: white;
  }
  &--edit {
    a {
      border-bottom: 0;
    }
  }
}

.store__hero {
  position: relative;
  padding: 0 10px 0 10px;
  text-align: right;
  min-height: 185px;
  height: 200px;

  img {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
    left: 0;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
  }
}

a.store--hover:hover {
  .store__hero:before {
    transition: opacity ease 0.5s;
    opacity: 0;
  }
}
