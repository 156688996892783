.pagination {
  display: flex;
  align-items: center;
  background: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: $shad;
  &__next {
    flex: 0 0 auto;
    padding: 1rem 3rem 1rem 1rem;
    a:after {
      margin-left: 1rem;
      content: '→'
    }
  }
  &__prev {
    flex: 0 0 auto;
    padding: 1rem 1rem 1rem 3rem;
    a:before {
      margin-right: 1rem;
      content: '←'
    }
  }
  &__text {
    flex: 3 0 auto;
    padding: 1rem;
    text-align: center;
  }
}

.back__button {
  margin: 0px 0 10px 5px;
  @media (min-width:415px) {
    margin: 35px 0 -30px 5px;
    padding-left: 30px;
  }
  text-decoration: none;
}