input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid $grey;
    &.input {
        &--error {
            border-color: red;
        }
    }
}

label {
    padding: 10px 0;
    display: block;
}

.form {
    background: white;
    padding: 2rem;
    box-shadow: $grad;
    &+.form {
        margin-top: 4rem;
    }
    // I know this isn't bem but meh
    h2 {
        margin: 0;
        font-size: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $grey;
    }
}

.form--flex {
    display: flex;
    flex-direction: column;
}

.login__form {
    max-width: 500px;
    margin: 50px auto;
    @media (max-width: 414px) {
        margin-top: 0;
    }
    .button {
        margin-top: 20px;
    }
}

.enquire__form {
    margin: 30px;
    h3 {
        color: #371178;
    }
}