.contact__page {
  .contact-title {
  
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 0;
    }
    
    h2, p {
      text-align: center;
      max-width: 420px;
      margin: 0 auto;
    }
    p {
      margin-bottom: 20px;
    }
  }
  .contact-details {
    display: flex;
    justify-content: center;
    @media (max-width: 414px) {
      flex-wrap: wrap;
    }
    .contact-phone, .contact-email {
      .contact-space {
        margin-left: 5px;
      }
      h3 {
        margin: 0 0 5px;;
      }
      p {
        margin: 0;
      }
      text-align: center;
      margin: 10px 50px;
      @media (max-width: 414px) {
        margin: 10px;
      }
    }
  }
  .contact-form__wrapper {
    
    max-width: 700px;
    margin: 0 auto;
    
    h3 {
      text-align: center;
    }
    @media (max-width: 414px) {
      form.card {
        padding: 2rem;
      }
    }
    
    .contact-field_wrap {
      display: flex;
      justify-content: space-between;
      @media (max-width:414px) {
        flex-wrap: wrap;
      }
      .contact-field_name, .contact-field_email {
        width: 48%;
        @media (max-width:414px) {
          width: 100%;
        }
      }
    }
  }

  .border {
    border-bottom: 1px solid #371178;
    max-width: 600px;
    margin: 10px auto;
  }

}

.contact__form {
  .email__card, .contact-text {
    @media all and (min-width:992px) {
      width: 670px;
      margin: 20px auto 0;
    }
  }
  form.email__card {
    padding: 5rem;
    @media (max-width:414px) {
      padding: 2rem;
    }
  }
}

.flex--contact {
  display: inline;
}


@media all and (max-width:414px) {
  .email__card, .contact-text {
    width: auto;
  }
  .contact-text {
    margin-top: -2rem;
  }
}