.admin__sidebar {
  width: 225px;
  height: 75vh;
  float: left;

  a.nav__link {
    text-transform: none;
    color: #371178;
    align-items: left;
    &:hover {
      background: $lightPurple;
      color: $white;
    }
  }
  .nav__link--active {
    background-color: #371178;
    color: white !important;
  }

  .nav__Section {
    margin-top: 43px;
    list-style: none;
    a {
      border: none;
    }
  }

  @media all and (max-width: 1400px) {
    margin-top: -2.8em;
  }

  @media all and (max-width: 900px) {
    width: 100%;
    height: auto;

    .nav__Section {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 17px;
      margin-top: 0;
    }
  }
}

.admin__main {
  margin: 0 50px;
  width: 100%;

  @media all and (max-width: 900px) {
    width: auto;
  }

  @media all and (max-width: 442px) {
    margin: 0 20px;
    .category__card {
      min-width: 300px;
      width: 100%;
      padding: 2rem;
      .btn-save {
        width: 100%;
      }
    }
  }

  select {
    text-transform: capitalize;
  }
}

a.admin__link {
  border-right: none !important;
}

.admin__editing {
  position: relative;
  top: -205px;
  left: 10px;
}
