@font-face {
    font-family: 'panama';
    src: url('/fonts/panama-bold-webfont.woff2') format('woff2'),
         url('/fonts/panama-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html {
  font-size:10px;
}

body {
  font-size:1.5rem;
  color:$black;
}

h1, h2, h3, h4, h5, h6, {
  // font-size: 5rem;
  // font-family: 'Panama';
  font-family: sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  // letter-spacing:-1px;
}

a {
  text-decoration: none;
  color:$black;
}

p a {
  border-bottom: 2px solid #371178;
}

p {
  line-height: 2;
  font-size: 1.6rem;
}

.title, .title--product {
  word-wrap: break-word;
  position: relative;
  z-index: 2;
  margin:0;
  font-size: 40px;
  z-index: 2;
  line-height:1.1;
  // transform: skew(0, -3deg);
  &--long {
    font-size: 30px;
  }
  &:before {
    content: '';
    width:50px;
    left:0;
    top:0;
    height:100%;
    // top:10%;
    display: block;
    position: absolute;
    z-index: -1;
    transform:skew(-5deg);
  }
  line-height:1.1;
  // inline link inside
  a {
    border-bottom: 0;
    $yellowa: rgba($yellow,0.8);
    background-image: linear-gradient(to right, $yellowa 100%, $yellowa 50%);
  }
}

.title--product{
  font-size: 14px;
}

.title--single {
  max-width: 600px;
  margin-top: -9rem;
  font-size: 10rem;
  text-align: center;
}
