.search {
  position: relative;
  width: 100%;
  display: flex;
  &__results {
    background: white;
    position: absolute;
    width: 100%;
    top:47px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 5;
    left: 0;
    @media all and (max-width: 900px) {
      top: 43px;
    }
  }
  &__input {
    font-size: 20px;
    background: none;
    color: $white;
    outline: 0;
    border: 0;
  }
  &__result {
    padding: 10px;
    display: block;
    border-bottom: 1px solid #ececec;
    &--active {
      background: #f1f1f1;
    }
    img {
      width: 6%;
      height: 6%;
    }
    &--margin {
      display: flex;
      strong, span {
        margin: 4px 0 4px 8px;
      }
      span {
        margin-left: 0;
      }
    }
  }
}

